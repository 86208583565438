import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import DotcomSharedComponentsLayout from '~/src/layouts/dotcom-shared-components-layout';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DotcomSharedComponentsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Note mdxType="Note">This information is only relevant to GitHub staff.</Note>
    <p>{`These components are shared design patterns by GitHub feature teams developing application-specific components. We encourage relying only on Primer components where possible, and not all patterns will be upstreamed to Primer.`}</p>
    <p>{`They're shared with other teams by developing them as React components within the `}<inlineCode parentName="p">{`ui/packages/`}</inlineCode>{` monorepo. This monorepo, along with the larger monolith, provides a solid foundation with baseline configurations for linting, accessibility scanning using Axe, and Storybook previews.`}</p>
    <p>{`Engineers building UI components should refer to the guidance on The Hub: `}<a parentName="p" {...{
        "href": "https://thehub.github.com/epd/engineering/dev-practicals/frontend/common-components/"
      }}>{`Building reusable UI Components`}</a>{` (only available to GitHub staff).`}</p>
    <h2>{`Related reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/contribute/handling-new-patterns"
        }}>{`Handling new patterns`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/contribute/adding-new-components#upstreaming-to-primer"
        }}>{`Upstreaming to Primer`}</a></li>
    </ul>
    <h2>{`Shared components`}</h2>
    <p>{`GitHub shared components are documented in a private Storybook instance: `}<a parentName="p" {...{
        "href": "https://gh.io/storybook"
      }}>{`ui/packages/ Storybook`}</a>{` (only available to GitHub staff).`}</p>
    <p>{`Please note that these components are `}<strong parentName="p">{`owned by the feature teams`}</strong>{`, and will not exist in our Figma component library, `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/Primer-Web?type=design&node-id=1406%3A0&mode=design&t=Qi8hXoRhKLLrDhgf-1"
      }}>{`Primer Web`}</a>{`, unless they have been upstreamed into Primer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      